<template lang="html">
<el-dialog title="修改状态" :visible.sync="dialogVisible">
  <el-form :model="form">
    <el-form-item label="禁止登录">
    	<el-radio-group v-model="form.status">
	      <el-radio :label="2">是</el-radio>
	      <el-radio :label="0">否</el-radio>
	    </el-radio-group>
    </el-form-item>
    <el-form-item label="惩罚原因">
      <el-input
      	style="width:300px"
			  type="textarea"
			  autosize
			  placeholder="请输入内容"
			  v-model="form.val1">
			</el-input>
    </el-form-item>
    <el-form-item label="解禁时间" class="flex-row" style="align-items:flex-start;">
      <el-radio-group v-model="form.val1">
	      <el-radio label="1">自定义</el-radio>
	      <el-radio label="2">永久</el-radio>
	    </el-radio-group>
	    <div v-show="form.val1 == 1">
		    <el-date-picker type="date" placeholder="请选择自定义的解禁时间" v-model="form.date1"></el-date-picker>
	    </div>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">关 闭</el-button>
    <el-button type="primary" @click="ensure">提 交</el-button>
  </div>
</el-dialog>
</template>

<script>
    export default {
    	data(){
    		return{
    			form:{
    				val1:'',
    				status:''
    			},
    			dialogVisible:false
    		}
    	},
    	components:{},
    	methods:{
        ensure(){
          this.$emit('editStatus',this.form)
          this.dialogVisible = false;
        }
      }
    }
</script>

<style scoped lang="scss">

</style>