<!--  -->
<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="refresh">
      <!-- 1.0 版本 无添加用户功能 -->
      <!-- <template v-slot:btns>
        <div class="btns">
          <el-button type="primary" size="medium" @click="addHandle">添加用户</el-button>
        </div>
      </template> -->
    </table-search>
    <div class="table">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
        @filter-change="filterChange"
        style="width: 100%">
        <el-table-column
        type="selection"
        :default-sort = "{prop: 'id', order: 'descending'}"
        width="55">
      </el-table-column>
        <el-table-column
          prop="id"
          sortable="custom"
          label="用户ID">
        </el-table-column>
        <el-table-column
          prop="nickName"
          label="用户名字">
          <template slot-scope="scope">
            <span v-if="!scope.row.identity">/</span>
            <span v-if="scope.row.identity === 'company'">{{scope.row.companyVo && scope.row.companyVo.companyName}}</span>
            <span v-if="scope.row.identity === 'user'">{{scope.row.userVo && scope.row.userVo.realName}}</span>
            <span v-if="scope.row.identity === 'lawyer'">{{scope.row.lawyerVo && scope.row.lawyerVo.realName}}</span>
            <span v-if="scope.row.identity === 'expert_party'">{{scope.row.expertVo && scope.row.expertVo.nickName}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="phoneNumber"
          label="注册手机">
        </el-table-column>
        <el-table-column
          prop="registerSource"
          label="注册来源">
          <template slot-scope="scope">
            <span style="color:#409EFF;" v-show="scope.row.registerSource==1">安卓</span>
            <span style="color:#67C23A;" v-show="scope.row.registerSource==2">IOS</span>
            <span style="color:#E6A23C;" v-show="scope.row.registerSource==3">后台</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="userStatus"
          :filters="statusFilter"
          column-key="userStatus"
          label="状态">
          <template slot-scope="scope">
            <status-field :status="scope.row.userStatus"></status-field>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          label="认证信息">
          <template slot="header">
              <single-selector :filters="authenFilter" text="认证信息" @command="handleCommand"></single-selector>
          </template>
          <template slot-scope="scope">
            <div> 
              <i class="dot" v-show="scope.row.val==0" style="color:#909399;">&bull;</i>
              <i class="dot" v-show="scope.row.val==1" style="color:#67C23A;">&bull;</i>
              <i class="dot" v-show="scope.row.val==2" style="color:#E6A23C;">&bull;</i>
              <i class="dot" v-show="scope.row.val==3" style="color:#F56C6C;">&bull;</i>
              <span v-if="scope.row.identity === 'expert_party'">认证企业-专家团</span>
              <span v-else-if="scope.row.identity && scope.row.identity !== 'expert_party'" style="vertical-align:middle;">认证{{identityType[scope.row.identity]}} - {{applyStatus[scope.row.applyStatus]}}</span>
              <span v-else>未认证</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="lastAccessTime"
          sortable="custom"
          label="最后访问">
        </el-table-column>
        <el-table-column
          prop="val"
          width="240"
          label="时间信息">
          <template slot-scope="scope">
            <div v-if="scope.row.identity !== 'expert_party'">注册时间：{{scope.row.createTime}}</div>
            <div v-if="scope.row.identity === 'expert_party'">开通时间：{{scope.row.approveTime}}</div>
            <div v-if="scope.row.applyStatus == '1' && scope.row.identity !== 'expert_party'">认证通过时间：{{scope.row.approveTime}}</div>    
            <div v-if="scope.row.identity && scope.row.applyStatus == '0'">认证提交时间：{{scope.row.applyTime}}</div>
            <div v-if="scope.row.identity && scope.row.applyStatus == '2'">认证驳回时间：{{scope.row.approveTime}}</div>
            <div v-if="scope.row.userStatus == '3'">注销时间：{{scope.row.unRegisterTime}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="val"
          label="操作">
          <template slot-scope="scope">
            <div>
              <el-button type="text" @click="checkHandle(scope.row)">查看信息</el-button>
            </div>
            <div>
              <el-button type="text" @click="userHandle(scope.row)">用户动态</el-button>
            </div>
            <div>
              <el-button type="text" @click="editHandle(scope.row)">修改状态</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="btns">
        <!-- <el-button type="primary" size="medium" @click="forbid">批量禁言</el-button> -->
        <!-- <el-button type="primary" size="medium" @click="forbidAll">批量禁止登陆</el-button> -->
      </div>
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          :current-page="form.page"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <viewinfo-dialog ref="viewinfoDialog" :info="info"></viewinfo-dialog>
    <user-dynamic ref="userDynamic"></user-dynamic>
    <edit-status ref="editStatus" @editStatus="editStatusHandle"></edit-status>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import ViewinfoDialog from '../../components/dialog/viewinfo-dialog.vue'
import UserDynamic from '../../components/dialog/user-dynamic.vue'
import EditStatus from '../../components/dialog/edit-status.vue'
import StatusField from '../../components/table/status-field.vue'
import SingleSelector from '../../components/table/single-selector.vue'
import {accountList,setStatus} from '../../api/member.js'
import {applyDetail} from '../../api/verify.js'
import {mapState} from 'vuex'
export default {
  data () {
    return {
      info:{
        phoneNumber:'',
        registerSource:''
      },
      accountId:'',
      loading:false,
      total:0,
      accountTypeOptions:{
        '1':'未认证',
        '2':'委托方',
        '3':'服务方',
        '4':'专家团'
      },
      accountDetailTypeOptions:{
        '1':'个人',
        '2':'企业',
        '3':'律师'
      },
      conditions:[
        {
            value: 'phoneNumber',
            label: '注册手机'
        },
        {
            value: 'accountId',
            label: '用户ID'
        },
        ],
        conditions2:{
          "1":"注册手机",
          "2":"用户ID"
        },
        form:{
          page:1,
          perPage:10,
          orderBy:'accountIdDesc',
          userStatus:'',
          identity:'',
          applyStatus:''
        },
      tableData:[
      ],
      multipleSelection:[],
      authenFilter:[
        {text: '全部',arrValue:['0','','']}, 
        {text: '未认证',arrValue:['1','no',null]}, 
        {text: '认证个人',arrValue:['2','user','1']}, 
        {text: '认证企业',arrValue:['3','company','1']},
        {text: '认证律师',arrValue:['4','lawyer','1']},
        {text: '认证企业-专家团',arrValue:['5','expert_party','1']},
        {text: '认证个人-审核中',arrValue:['6','user','0']},
        {text: '认证企业-审核中',arrValue:['7','company','0']},
        {text: '认证律师-审核中', arrValue:['8','lawyer','0']},
        {text: '认证个人-审核拒绝',arrValue:['9','user','2']},
        {text: '认证企业-审核拒绝',arrValue:['10','company','2']},
        {text: '认证律师-审核拒绝',arrValue:['11','lawyer','2']},
        ],//认证信息
        identityTypeFlag:false,
    };
  },
  components: {
    TableSearch,
    ViewinfoDialog,
    UserDynamic,
    EditStatus,
    StatusField,
    SingleSelector
  },
  computed: {
    ...mapState({
      statusOptions:state => state.dictionary.statusOptions,
      identityType:state => state.dictionary.identityType,
      applyStatus:state => state.dictionary.applyStatus
    }),
    statusFilter:function(){
      let res = [];
      for(let key in this.statusOptions){
        res.push({
          value:key,
          text:this.statusOptions[key]
        })
      }
      return res;
    }
  },
  methods: {
    handleCommand(command) {
      this.form.identity = command[1];
      this.form.applyStatus = command[2];
      this.getList();
    },
    searchRes(para){
      console.log(para)
      this.form = Object.assign({
        page:1,
        perPage:this.form.perPage,
        orderBy:this.form.orderBy,
        userStatus:this.form.userStatus,
        identity:this.form.identity,
        applyStatus:this.form.applyStatus
      },para)
      this.getList();
    },
    refresh(){
      this.getList();
    },
    getTotal(){},
    getList(){
      this.loading = true;
      accountList(this.form).then(res=>{
        let data = res.data;
        if(data.code ==='000'){
          this.tableData = data.content.list;
          this.loading = false;
          this.total = data.content.total;
        }else{
          console.log(data.desc)
        }
      })
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage = val;
      this.form.page = 1;
      this.getList();
    },
    addHandle(){},
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val)
    },
    checkHandle(row){
      this.getDetail(row);
      this.$refs.viewinfoDialog.dialogVisible = true;
    },
    getDetail(row){
      let para={
        applyId:row.applyId,
        type:row.identity
      }
      applyDetail(para).then(res=>{
        let data = res.data;
        if(data.code==='000'){
          if(para.type === 'expert_party'){
            this.info = row;
          }else{
            this.info = data.content || {};
            this.info.loginPhoneNumber = row.phoneNumber;
            this.info.registerSource = row.registerSource;
          }
          this.info.type = row.identity;
          
        }else{
          console.log(data.desc)
        }
      })
    },
    userHandle(row){
      this.$refs.userDynamic.dialogVisible = true;
    },
    editHandle(row){
      this.accountId = row.id;
      this.$refs.editStatus.form.status = row.status;
      this.$refs.editStatus.dialogVisible = true;
    },
    editStatusHandle(form){
      let para = {
        accountId:this.accountId,
        status:form.status
      }
      setStatus(para).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.$message.success(data.desc)
          this.getList();
        }else{
          this.$message.warning(data.desc)
          console.log(data.desc)
        }
      })
    },
    // forbidAll(){},
    // forbid(){},
    sortChange(column, prop, order ){
      let key="";
      let order2 = column.order === 'descending'?'Desc':'Asc';
      if(column.prop === 'id'){
        key = 'accountId' + order2;
      }else{
        key = column.prop + order2;
      }
      
      this.form.orderBy = key;
      this.getList();
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    filterChange(filters){
      if(filters.userStatus){
        this.form.userStatus = filters.userStatus.join();
      }
      this.form.page = 1;
      this.getList();
    },
    infoFilterHandle(){
      this.identityTypeFlag = true;
    }
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: space-between;
  margin:15px 0;
}
</style>